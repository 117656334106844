import { Box, Heading } from "@chakra-ui/react";
import RichTable from "../data/RichTable";

export default function Richies() {
  return (
    <Box>
      <Heading>
        Richies LUNES
      </Heading>
      <RichTable />
    </Box>
  )
}
